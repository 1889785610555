import * as Sentry from '@sentry/browser';

const {
  withSentry,
  sentryDSN,
  sentryTracesSampleRate,
  sentryLevel,
  sentryUseReplay,
  sentryReplayErrorSampleRate,
  sentryReplaySessionSampleRate,
} = window.data;

if (withSentry) {
  const integrations = [Sentry.browserTracingIntegration()];

  if (sentryUseReplay) {
    integrations.push(Sentry.replayIntegration());
  }

  Sentry.init({
    dsn: sentryDSN,
    integrations,
    tracesSampleRate: parseFloat(sentryTracesSampleRate),
    replaysSessionSampleRate: parseFloat(sentryReplaySessionSampleRate),
    replaysOnErrorSampleRate: parseFloat(sentryReplayErrorSampleRate),
  });

  // https://docs.sentry.io/platforms/javascript/enriching-events/level/
  if (sentryLevel) {
    Sentry.getCurrentScope().setLevel(sentryLevel.toLowerCase());
  }
}
